questionnaireApp = angular.module("questionnaireApp.directives", [])

####################################################################
## shuffle
####################################################################
shuffle = (arr) ->
    i = arr.length

    while --i
        j = Math.floor(Math.random() * (i+1))
        [arr[i], arr[j]] = [arr[j], arr[i]]

    return arr unless i > 0

####################################################################
## BLOCK SUCCESSFACTOR
####################################################################
questionnaireApp.directive "blockSuccessfactor", [ ->
    scope:      true
    replace:    true
    transclude: true
    scope:
        item:       "="
        responses:  "="
        triggers:   "="
        settings:   "="
        labels:     "="
        mandatory:  "="
    template: """
            <div class="question" ng-show="triggers.isQuestionnaireItemVisible(item, responses) && triggers.isQuestionnaireItemHeaderVisible(item, responses)">
                <div class="question-text" ng-bind-html="label"></div>
                <div>
                    <div class="large-only stacktable component">
                        <div class="sticky-wrap">
                            <table class="sticky-enabled sf-table" style="margin: 0px; width: 100%;" id="area_{{item.id}}">
                                <thead fsm-sticky-header scroll-body="#area_{{item.id}}" scroll-stop="0">
                                    <tr>
                                        <th style="width: 369px;"><div>&nbsp;</div></th>
                                        <th ng-repeat-start="subindicator in settings.questionnaireSubindicators" colspan="{{subindicator.scale}}" class="sf-header question-text" ng-bind-html="subindicatorlabels[subindicator.prefix]['label']"></th>
                                        <th ng-repeat-end class="sf-spacer" ng-if="!$last"><div><span>&nbsp;</span></div></th>
                                    </tr>

                                    <tr>
                                        <th ng-if="0" ng-repeat-start="subindicator in settings.questionnaireSubindicators" class="sf-label"></th>
                                        <th><div><span>&nbsp;</span></div></th>
                                        <th class="sf-label" ng-repeat="n in [] | range:subindicator.scale"><div><span ng-bind-html="subindicatorlabels[subindicator.prefix]['ranges'][n]"></span></div></th>
                                        <th ng-if="0" ng-repeat-end></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr block-indicator ng-repeat="indicator in item.items" item="indicator" responses="responses" triggers="triggers" settings="settings"   labels="labels" mandatory="mandatory"    ng-show="triggers.isQuestionnaireItemVisible(indicator, responses)"></tr>
                                </tbody>
                            </table>
                            <table class="sticky-thead" style="width: 1000px; opacity: 0; top: 0px;">
                                <thead>
                                    <tr>
                                        <th ng-if="0" ng-repeat-start="subindicator in settings.questionnaireSubindicators"></th>
                                        <th><div><span>&nbsp;</span></div></th>
                                        <th class="sf-label" ng-repeat="n in [] | range:subindicator.scale"><div ng-bind-html="subindicatorlabels[subindicator.prefix]['ranges'][n]"></div></th>
                                        <th ng-if="0" ng-repeat-end></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                    <table block-indicator-mobile ng-repeat="indicator in item.items" class="large-only stacktable small-only sf-table"  item="indicator" responses="responses" triggers="triggers" settings="settings"  labels="labels" mandatory="mandatory"  ng-show="triggers.isQuestionnaireItemVisible(indicator, responses)"></table>
                </div>
                <div class="clear"><!-- --></div>
            </div>
            """
    controller: ($scope, $element, $attrs, $sce) ->

        $scope.initializeLabels = ->
            $scope.label              = $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelById($scope.item.id, $scope.settings.respondentLanguage))
            $scope.subindicatorlabels = {}


            for subindicator in $scope.settings.questionnaireSubindicators
                $scope.subindicatorlabels[subindicator.prefix] = {label: $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelByName(subindicator.prefix, $scope.settings.respondentLanguage)), ranges:{}}
                i = 1
                while i <= subindicator.scale
                    idRange = subindicator.prefix + '_' + i
                    $scope.subindicatorlabels[subindicator.prefix]['ranges'][i] =  $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelByName(idRange, $scope.settings.respondentLanguage))
                    i++


        $scope.$watch "settings.respondentLanguage", ((newVal, oldVal) ->
            $scope.initializeLabels()
        ), true

        return
]


####################################################################
## BLOCK INDICATOR
####################################################################
questionnaireApp.directive "blockIndicator", [ ->
    scope:      true
    replace:    false
    restrict:   'A'
    scope:
        item:       "="
        responses:  "="
        triggers:   "="
        settings:   "="
        labels:     "="
        mandatory:  "="
    template: """
                <td ng-class="getIndicatorClass()"><div ng-bind-html="label"></div>&nbsp; <span ng-bind-html="required"></span></td>
                <td ng-if="0" ng-repeat-start="subindicator in settings.questionnaireSubindicators"></td>
                <td ng-class="{'sf-indicator': subindicator.prefix!='C', 'sf-remark': subindicator.prefix=='C'}" ng-repeat="n in [] | range:subindicator.scale">

                    <input type="radio" class="radio-custom" ng-if="subindicator.prefix!='C'" name="{{item.id}}_{{subindicator.prefix}}" id="{{item.id}}_{{subindicator.prefix}}_{{n}}" value="{{item.id}}_{{subindicator.prefix}}_{{n}}" ng-model="responses[item.id][subindicator.prefix]"/>

                    <textarea ng-if="subindicator.prefix=='C'" name="{{item.id}}_{{subindicator.prefix}}" id="{{item.id}}_{{subindicator.prefix}}_{{n}}" rows="1" class="form-control" style="max-width: 100px;" ng-model="responses[item.id][subindicator.prefix]" ng-click="doSelectComment()"></textarea>

                    <label for="{{item.id}}_{{subindicator.prefix}}_{{n}}" ng-if="subindicator.prefix!='C'"  class="radio-custom-label answer-text" ng-model="responses[item.id][subindicator.prefix]"></label>

                </td>
                <td class="sf-spacer" ng-if="!$last"></td>
                <td ng-if="0" ng-repeat-end></td>

            """
    controller: ($scope, $element, $attrs, $sce) ->
        $scope.required     = ''

        $scope.initializeLabels = ->
            $scope.label    = $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelById($scope.item.id, $scope.settings.respondentLanguage))

        if not $scope.responses.hasOwnProperty($scope.item.id)
            $scope.responses[$scope.item.id] = {}
            for subindicator in $scope.settings.questionnaireSubindicators
                if not $scope.responses[$scope.item.id].hasOwnProperty(subindicator.prefix)
                    $scope.responses[$scope.item.id][subindicator.prefix] = null

        $scope.getIndicatorClass = ->
            required = false
            for subindicatorId,subindicatorRequired of $scope.item.required
                if subindicatorRequired
                    required = true
            if required
                if $scope.settings.browsingState == 'STATE_CHECK_REQUIRED' and $scope.item.id in $scope.mandatory
                    $scope.required = $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelByName('REQUIRED', $scope.settings.respondentLanguage))
                    return 'sf-question mandatory-active'
                else
                    $scope.required = ''
                    return 'sf-question mandatory'
            else
                $scope.required = ''
                return 'sf-question'

        $scope.$watch "settings.respondentLanguage", ((newVal, oldVal) ->
            $scope.initializeLabels()
        ), true


        $scope.doSelectComment= ->
            $scope.$emit "onSelectComment", {'item':$scope.item.id, 'label':$scope.labels.getQuestionnaireItemLabelById($scope.item.id, $scope.settings.respondentLanguage)}

]


####################################################################
## BLOCK INDICATOR MOBILE
####################################################################
questionnaireApp.directive "blockIndicatorMobile", [ ->
    scope:      true
    replace:    false
    restrict:   'A'
    scope:
        item:       "="
        responses:  "="
        triggers:   "="
        settings:   "="
        labels:     "="
        mandatory:  "="
    template: """
                <tbody>
                    <tr>
                        <th ng-class="isIndicatorRequired() ? 'st-head-row mandatory' : 'st-head-row'" colspan="2" id="{{item.id}}_m"><div ng-bind-html="label"></div></th>
                    </tr>
                    <tr ng-if="0" ng-repeat-start="subindicator in settings.questionnaireSubindicators"></tr>
                    <tr>
                        <td class="st-head-row" colspan="2"><div><span ng-bind-html="subindicatorlabels[subindicator.prefix]['label']"></span></div></td>
                    </tr>
                    <tr ng-repeat="n in [] | range:subindicator.scale">
                        <td class="sf-label st-key"><div><span ng-bind-html="subindicatorlabels[subindicator.prefix]['ranges'][n]"></span></div></td>
                        <td class="sf-indicator st-val">
                            <input type="radio" class="radio-custom" ng-if="subindicator.prefix!='C'" name="M_{{item.id}}_{{subindicator.prefix}}" id="M_{{item.id}}_{{subindicator.prefix}}_{{n}}" value="{{item.id}}_{{subindicator.prefix}}_{{n}}" ng-model="responses[item.id][subindicator.prefix]"/>

                            <textarea ng-if="subindicator.prefix=='C'" name="M_{{item.id}}_{{subindicator.prefix}}" id="M_{{item.id}}_{{subindicator.prefix}}_{{n}}" rows="3" class="form-control" style="max-width: 100px;" ng-model="responses[item.id][subindicator.prefix]"></textarea>

                            <label for="{{item.id}}_{{subindicator.prefix}}_{{n}}" ng-if="subindicator.prefix!='C'"  class="radio-custom-label answer-text" ng-model="responses[item.id][subindicator.prefix]"></label>
                        </td>
                    </tr>
                    <tr ng-if="0" ng-repeat-end></td>
                </tbody>
            """
    controller: ($scope, $element, $attrs, $sce) ->

        $scope.initializeLabels = ->
            $scope.label              = $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelById($scope.item.id, $scope.settings.respondentLanguage))
            $scope.subindicatorlabels = {}

            for subindicator in $scope.settings.questionnaireSubindicators
                $scope.subindicatorlabels[subindicator.prefix] = {label: $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelByName(subindicator.prefix, $scope.settings.respondentLanguage)), ranges:{}}
                i = 1
                while i <= subindicator.scale
                    idRange = subindicator.prefix + '_' + i
                    $scope.subindicatorlabels[subindicator.prefix]['ranges'][i] =  $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelByName(idRange, $scope.settings.respondentLanguage))
                    i++

        if not $scope.responses.hasOwnProperty($scope.item.id)
            $scope.responses[$scope.item.id] = {}
            for subindicator in $scope.settings.questionnaireSubindicators
                if not $scope.responses[$scope.item.id].hasOwnProperty(subindicator.prefix)
                    $scope.responses[$scope.item.id][subindicator.prefix] = null

        $scope.isIndicatorRequired = ->
            required = false
            for subindicatorId,subindicatorRequired of $scope.item.required
                if subindicatorRequired
                    required = true
            return required

        $scope.$watch "settings.respondentLanguage", ((newVal, oldVal) ->
            $scope.initializeLabels()
        ), true

        return
]


####################################################################
## BLOCK MATRIX
####################################################################
questionnaireApp.directive "blockMatrix", [ ->
    scope:      true
    replace:    true
    transclude: true
    scope:
        item:       "="
        responses:  "="
        triggers:   "="
        settings:   "="
        labels:     "="
        mandatory:  "="
    template: """
            <div class="question" ng-show="triggers.isQuestionnaireItemVisible(item, responses) && triggers.isQuestionnaireItemHeaderVisible(item, responses)">
                <div class="question-text" ng-bind-html="label"></div>
                <div>
                    <div class="large-only stacktable component">
                        <div class="sticky-wrap">
                            <table class="sticky-enabled sf-table" style="margin: 0px; width: 100%;">
                                <thead>
                                    <tr>
                                        <th style="width: 369px;"><div>&nbsp;</div></th>
                                        <th ng-repeat-start="subindicator in item.subindicators" colspan="{{subindicator.scale}}" class="sf-header question-text" ><span ng-bind-html="subindicatorlabels[subindicator.id]['label']"></span></th>
                                        <th ng-repeat-end class="sf-spacer" ng-if="!$last"><div><span>&nbsp;</span></div></th>
                                    </tr>

                                    <tr>
                                        <th ng-if="0" ng-repeat-start="subindicator in item.subindicators" class="sf-label"></th>
                                        <th><div><span>&nbsp;</span></div></th>
                                        <th class="sf-label" ng-repeat="n in [] | range:subindicator.scale "><div><span ng-bind-html="subindicatorlabels[subindicator.id]['ranges'][n]"></span></div></th>
                                        <th ng-if="0" ng-repeat-end></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr block-matrix-question ng-repeat="question in item.items" item="question" subindicators="item.subindicators" responses="responses"  triggers="triggers" settings="settings" labels="labels" mandatory="mandatory"  ng-show="triggers.isQuestionnaireItemVisible(question, responses)"></tr>
                                </tbody>
                            </table>
                            <table class="sticky-thead" style="width: 1000px; opacity: 0; top: 0px;">
                                <thead>
                                    <tr>
                                        <th><div><span>&nbsp;</span></div></th>
                                        <th ng-if="0" ng-repeat-start="subindicator in item.subindicators"></th>
                                        <th class="sf-label"><div><span></span></div></th>
                                        <th class="sf-label"><div><span></span></div></th>
                                        <th class="sf-label"><div><span></span></div></th>
                                        <th ng-if="0" ng-repeat-end></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                    <table block-matrix-question-mobile ng-repeat="question in item.items" class="large-only stacktable small-only sf-table"  item="question" subindicators="item.subindicators" responses="responses" triggers="triggers" settings="settings"  labels="labels" mandatory="mandatory"  ng-show="triggers.isQuestionnaireItemVisible(question, responses)"></table>
                </div>
                <div class="clear"><!-- --></div>
            </div>
            """
    controller: ($scope, $element, $attrs, $sce) ->
        for subindicator in $scope.item.subindicators
            subindicator.scale = Number(subindicator.scale)

        $scope.initializeLabels = ->
            $scope.subindicatorlabels = {}
            $scope.label  = $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelById($scope.item.id, $scope.settings.respondentLanguage))
            for subindicator in $scope.item.subindicators
                $scope.subindicatorlabels[subindicator.id] = {label: $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelById(subindicator.id, $scope.settings.respondentLanguage)), ranges:{}}
                i = 1
                while i <= subindicator.scale
                    idRange = subindicator.id + '_' + i
                    label   = $scope.labels.getQuestionnaireItemLabelById(idRange, $scope.settings.respondentLanguage)
                    $scope.subindicatorlabels[subindicator.id]['ranges'][i] =  $sce.trustAsHtml(label)
                    i++

        $scope.$watch "settings.respondentLanguage", ((newVal, oldVal) ->
            $scope.initializeLabels()
        ), true

        return
]


####################################################################
## BLOCK MATRIX QUESTION
####################################################################
questionnaireApp.directive "blockMatrixQuestion", [ ->
    scope:      true
    replace:    false
    restrict:   'A'
    scope:
        item:           "="
        subindicators : "="
        triggers:       "="
        responses:      "="
        settings:       "="
        labels:         "="
        mandatory:      "="
    template: """
                <td ng-class="getQuestionClass()"><span ng-bind-html="label"></span> &nbsp; <span ng-bind-html="required"></span></td>
                <td ng-if="0" ng-repeat-start="subindicator in subindicators"></td>
                <td ng-class="sf-indicator" ng-repeat="n in [] | range:subindicator.scale">

                    <div ng-if="item.form=='SINGLECHOICE'">

                        <input type="radio" class="radio-custom" name="{{item.id}}_{{subindicator.id}}" id="{{item.id}}_{{subindicator.id}}_{{n}}" value="{{item.id}}_{{subindicator.id}}_{{n}}" ng-model="responses[item.id][subindicator.id]" />

                        <label for="{{item.id}}_{{subindicator.id}}_{{n}}" class="radio-custom-label answer-text"></label>

                    </div>

                    <div ng-if="item.form=='MULTIPLECHOICE'">

                        <input type="checkbox" class="checkbox-custom" name="{{item.id}}_{{subindicator.id}}" id="{{item.id}}_{{subindicator.id}}_{{n}}" value="{{item.id}}_{{subindicator.id}}_{{n}}" ng-model="responses[item.id][subindicator.id][n]" />

                        <label for="{{item.id}}_{{subindicator.id}}_{{n}}" class="checkbox-custom-label answer-text"></label>

                    </div>


                </td>
                <td class="sf-spacer" ng-if="!$last"></td>
                <td ng-if="0" ng-repeat-end></td>

            """
    controller: ($scope, $element, $attrs, $sce) ->

        $scope.required           = ''

        $scope.initializeLabels = ->
            $scope.label  = $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelById($scope.item.id, $scope.settings.respondentLanguage))

        if not $scope.responses.hasOwnProperty($scope.item.id)
            $scope.responses[$scope.item.id] = {}
            for subindicator in $scope.subindicators
                if not $scope.responses[$scope.item.id].hasOwnProperty(subindicator.id)
                    if $scope.item.form == 'MULTIPLECHOICE'
                        $scope.responses[$scope.item.id][subindicator.id] = {}
                    else
                        $scope.responses[$scope.item.id][subindicator.id] = null

        $scope.getQuestionClass = ->
            if $scope.item.required
                if $scope.settings.browsingState == 'STATE_CHECK_REQUIRED' and $scope.item.id in $scope.mandatory
                    $scope.required = $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelByName('REQUIRED', $scope.settings.respondentLanguage))
                    return 'sf-question mandatory-active'
                else
                    $scope.required = ''
                    return 'sf-question mandatory'
            else
                $scope.required = ''
                return 'sf-question'

        $scope.$watch "settings.respondentLanguage", ((newVal, oldVal) ->
            $scope.initializeLabels()
        ), true

        return
]


####################################################################
## BLOCK MATRIX QUESTION MOBILE
####################################################################
questionnaireApp.directive "blockMatrixQuestionMobile", [ ->
    scope:      true
    replace:    false
    restrict:   'A'
    scope:
        item:           "="
        subindicators : "="
        responses:      "="
        triggers:       "="
        settings:       "="
        labels:         "="
        mandatory:      "="
    template: """
                <tbody>
                    <tr>
                        <th ng-class="getQuestionClass()" colspan="2" id="{{item.id}}_m"><span ng-bind-html="label"></span> &nbsp; <span ng-bind-html="required"></span></th>
                    </tr>
                    <tr ng-if="0" ng-repeat-start="subindicator in subindicators"></tr>
                    <tr>
                        <td class="st-head-row" colspan="2"><div><span ng-bind-html="subindicatorlabels[subindicator.id]['label']"></span></div></td>
                    </tr>
                    <tr ng-repeat="n in [] | range:subindicator.scale">
                        <td class="sf-label st-key"><div><span ng-bind-html="subindicatorlabels[subindicator.id]['ranges'][n]"></span></div></td>
                        <td class="sf-indicator st-val">
                            <input type="radio" class="radio-custom" name="M_{{item.id}}_{{subindicator.id}}" id="M_{{item.id}}_{{subindicator.id}}_{{n}}" value="{{item.id}}_{{subindicator.id}}_{{n}}" ng-model="responses[item.id][subindicator.id]" />

                            <label for="M_{{item.id}}_{{subindicator.id}}_{{n}}" class="radio-custom-label answer-text"></label>
                        </td>
                    </tr>
                    <tr ng-if="0" ng-repeat-end></td>
                </tbody>
            """
    controller: ($scope, $element, $attrs, $sce) ->

        $scope.required           = ''

        $scope.initializeLabels = ->
            $scope.label              = $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelById($scope.item.id, $scope.settings.respondentLanguage))
            $scope.subindicatorlabels = {}

            for subindicator in $scope.subindicators
                $scope.subindicatorlabels[subindicator.id] = {label: $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelById(subindicator.id, $scope.settings.respondentLanguage)), ranges:{}}
                i = 1
                while i <= subindicator.scale
                    idRange = subindicator.id + '_' + i
                    label   = $scope.labels.getQuestionnaireItemLabelById(idRange, $scope.settings.respondentLanguage)
                    $scope.subindicatorlabels[subindicator.id]['ranges'][i] =  $sce.trustAsHtml(label)
                    i++

        if not $scope.responses.hasOwnProperty($scope.item.id)
            $scope.responses[$scope.item.id] = {}
            for subindicator in $scope.subindicators
                if not $scope.responses[$scope.item.id].hasOwnProperty(subindicator.id)
                    $scope.responses[$scope.item.id][subindicator.id] = null
        $scope.getQuestionClass = ->
            if $scope.item.required
                if $scope.settings.browsingState == 'STATE_CHECK_REQUIRED' and $scope.item.id in $scope.mandatory
                    $scope.required = $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelByName('REQUIRED', $scope.settings.respondentLanguage))
                    return 'st-head-row mandatory-active'
                else
                    $scope.required = ''
                    return 'st-head-row mandatory'
            else
                $scope.required = ''
                return 'st-head-row'

        $scope.$watch "settings.respondentLanguage", ((newVal, oldVal) ->
            $scope.initializeLabels()
        ), true

        return
]



####################################################################
## BLOCK MATRIX NO HEADER
####################################################################
questionnaireApp.directive "blockMatrixNoHeader", [ ->
    scope:      true
    replace:    true
    transclude: true
    scope:
        item:       "="
        responses:  "="
        triggers:   "="
        settings:   "="
        labels:     "="
        mandatory:  "="
    template: """
            <div class="question" ng-show="triggers.isQuestionnaireItemVisible(item, responses)">
                <div class="question-text" ng-class="getQuestionClass()"><span ng-bind-html="label"></span></div>
                <div>
                    <table class="sd-table">
                    <tr ng-repeat="subindicator in item.subindicators">
                        <td class="sd-label-left"><span ng-bind-html="subindicatorlabels[subindicator.id]['left']"></span></td>
                        <td class="sd-label" ng-repeat="n in [] | range:subindicator.scale" ng-if="item.items[0].form=='SINGLECHOICE' && item.items[0].style!='SLIDER'">
                            <input class="radio-custom" name="{{item.items[0].id}}_{{subindicator.id}}" id="{{item.items[0].id}}_{{subindicator.id}}_{{n}}" type="radio" value="{{item.items[0].id}}_{{subindicator.id}}_{{n}}" ng-model="responses[item.items[0].id][subindicator.id]">
                            <label for="{{item.items[0].id}}_{{subindicator.id}}_{{n}}" class="radio-custom-label answer-text"></label>
                        </td>
                        <td class="sd-label" ng-if="item.items[0].form=='SINGLECHOICE' &&  item.items[0].style=='SLIDER'">
                            <input input kendo-slider name="{{item.items[0].id}}_{{subindicator.id}}"  id="{{item.items[0].id}}_{{subindicator.id}}_{{n}}" ng-model="responses[item.items[0].id][subindicator.id]"></input>
                        </td>
                        <td class="sd-label-right"><span ng-bind-html="subindicatorlabels[subindicator.id]['right']"></span></td>
                    </tr>
                    </table>
                </div>
                <div class="clear"><!-- --></div>
            </div>
            """
    controller: ($scope, $element, $attrs, $sce) ->
        for subindicator in $scope.item.subindicators
            subindicator.scale = Number(subindicator.scale)

        if not $scope.responses.hasOwnProperty($scope.item.items[0].id)
            $scope.responses[$scope.item.items[0].id] = {}
            for subindicator in $scope.item.subindicators
                if not $scope.responses[$scope.item.items[0].id].hasOwnProperty(subindicator.id)
                    $scope.responses[$scope.item.items[0].id][subindicator.id] = null

        $scope.initializeLabels = ->
            $scope.label              = $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelById($scope.item.items[0].id, $scope.settings.respondentLanguage))
            $scope.subindicatorlabels = {}

            for subindicator in $scope.item.subindicators
                $scope.subindicatorlabels[subindicator.id] = {left: $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelById(subindicator.id + '_1', $scope.settings.respondentLanguage)), right: $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelById(subindicator.id + '_' + subindicator.scale, $scope.settings.respondentLanguage))}


        $scope.getQuestionClass = ->
            if $scope.item.items[0].required
                if $scope.settings.browsingState == 'STATE_CHECK_REQUIRED' and $scope.item.items[0].id in $scope.mandatory
                    return 'question-text mandatory-active'
                else
                    return 'question-text mandatory'
            else
                return 'question-text'

        $scope.$watch "settings.respondentLanguage", ((newVal, oldVal) ->
            $scope.initializeLabels()
        ), true

        return
]



####################################################################
## BLOCK MATRIX DRAG AND DROP
####################################################################
questionnaireApp.directive "blockMatrixDragAndDrop", [ ->
    scope:      true
    replace:    true
    transclude: true
    scope:
        item:           "="
        responses:      "="
        triggers:       "="
        labels:         "="
        settings:       "="
        mandatory:      "="
    template: """
                <div class="question" ng-show="triggers.isQuestionnaireItemVisible(item, responses)">
                    <div class="question-text" ><span ng-bind-html="label"></span></div>
                    <div>
                        <div id="sortablelist_source_{{item.id}}" kendo-sortable k-connect-with="getConnectedListsNameForSource()" k-on-end="OnChangeSourceList(kendoEvent)">
                            <div class="draggable ui-widget-content" ng-repeat="subitem in sourceDisplayItems" ng-bind-html="subitemslabels[subitem.id]"></div>
                        </div>

                        <div class="clear"><!-- --></div>

                        <table>
                        <tr style='background-color: transparent!important;'>
                            <td align='center' valign='top' ng-repeat="n in [] | range:item.subindicators[0].scale">
                                <div ng-bind-html="subindicatorlabels[n]" style='text-align: center;'></div>
                                <div style='border: 1px dashed #999; min-height: 100px; text-align: center; margin: 5px;' id="target_{{n}}" kendo-sortable k-connect-with="getConnectedListsNameForTarget({{n}})" k-on-end="OnChangeTargetList({{n}}, kendoEvent)">
                                    <span ng-show='targetDisplayItems[n].length == 0' style='min-height: 100px;'>&nbsp;</span>
                                    <div  ng-repeat="subitem in targetDisplayItems[n]" ng-bind-html="subitemslabels[subitem.id]"></div>
                                </div>
                            </td>
                        </tr>

                        </table>

                    </div>

                <div class="clear"><!-- --></div>
                </div>
            """
    controller: ($scope, $element, $attrs, $sce) ->
        $scope.sourceItems        = []
        $scope.targetItems        = {}
        $scope.sourceDisplayItems = []
        $scope.targetDisplayItems = {}
        $scope.moveItem           = null

        for subindicator in $scope.item.subindicators
            subindicator.scale = Number(subindicator.scale)

        $scope.initializeLabels = ->
            $scope.label              = $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelById($scope.item.id, $scope.settings.respondentLanguage))
            $scope.subitemslabels     = {}
            $scope.subindicatorlabels = {}

            for index in [1..$scope.item.subindicators[0].scale]
                label = $scope.labels.getQuestionnaireItemLabelById($scope.item.subindicators[0].id + '_' + index, $scope.settings.respondentLanguage)
                $scope.subindicatorlabels[index] = $sce.trustAsHtml(label)

            for subitem in $scope.item.items
                label = $scope.labels.getQuestionnaireItemLabelById(subitem.id, $scope.settings.respondentLanguage)
                $scope.subitemslabels[subitem.id] = $sce.trustAsHtml(label)


        for subitem in $scope.item.items
            if not $scope.responses.hasOwnProperty(subitem.id)
                $scope.responses[subitem.id] = -1

        for index in [1..$scope.item.subindicators[0].scale]
            $scope.targetItems[index]        = []
            $scope.targetDisplayItems[index] = []

        for subitem in $scope.item.items
            if $scope.responses.hasOwnProperty(subitem.id)
                subindicatorId = $scope.responses[subitem.id]
                if subindicatorId  > -1
                    $scope.targetDisplayItems[subindicatorId].push {id:subitem.id, text:subitem.text}
                    $scope.targetItems[subindicatorId].push subitem.id
                else
                    if $scope.triggers.isQuestionnaireItemVisible(subitem, $scope.responses)
                        $scope.sourceDisplayItems.push {id:subitem.id, text:subitem.text}
                        $scope.sourceItems.push subitem.id
            else
                if $scope.triggers.isQuestionnaireItemVisible(subitem, $scope.responses)
                    $scope.sourceDisplayItems.push {id:subitem.id, text:subitem.text}
                    $scope.sourceItems.push subitem.id


        $scope.getConnectedListsNameForSource = ->
            listOfNames = ""
            for index in [1..$scope.item.subindicators[0].scale]
                if listOfNames.length > 0
                    listOfNames = listOfNames + ","
                listOfNames = listOfNames + "#target_" + index
            return listOfNames

        $scope.getConnectedListsNameForTarget = (listIndex) ->
            listOfNames = "#sortablelist_source_" + $scope.item.id
            for index in [1..$scope.item.subindicators[0].scale]
                if index != listIndex
                    listOfNames = listOfNames + ",#target_" + index
            return listOfNames


        $scope.OnChangeSourceList = (e) ->
            if e.action == 'remove'
                $scope.moveItem = $scope.sourceItems.splice(e.oldIndex, 1)[0]
            if e.action == 'receive'
                $scope.sourceItems.splice(e.newIndex, 0, $scope.moveItem)


        $scope.OnChangeTargetList = (index, e) ->
            if e.action == 'remove'
                $scope.moveItem = $scope.targetItems[index].splice(e.oldIndex, 1)[0]
                $scope.responses[$scope.moveItem] = -1
            if e.action == 'receive'
                $scope.targetItems[index].splice(e.newIndex, 0, $scope.moveItem)
                $scope.responses[$scope.moveItem] = index

        $scope.$watch "settings.respondentLanguage", ((newVal, oldVal) ->
            $scope.initializeLabels()
        ), true

        return
]



####################################################################
## BLOCK TEXT
####################################################################
questionnaireApp.directive "blockText", [ ->
    scope:      true
    replace:    true
    transclude: true
    scope:
        item:       "="
        responses:  "="
        triggers:   "="
        labels:     "="
        settings:   "="
    template: """
            <div class="question" ng-show="triggers.isQuestionnaireItemVisible(item, responses)">
                <div class="question-text"></div>
                <div ng-bind-html="label"></div>
                <div class="clear"><!-- --></div>
            </div>
            """
    controller: ($scope, $element, $attrs, $sce) ->

        $scope.initializeLabels = ->
            $scope.label              = $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelById($scope.item.id, $scope.settings.respondentLanguage))

        $scope.$watch "settings.respondentLanguage", ((newVal, oldVal) ->
            $scope.initializeLabels()
        ), true

        return
]


####################################################################
## BLOCK QUESTION
####################################################################
questionnaireApp.directive "blockQuestion", [ ->
    scope:      true
    replace:    true
    transclude: true
    scope:
        item:       "="
        responses:  "="
        triggers:   "="
        labels:     "="
        settings:   "="
        mandatory:  "="
    template: """
            <div class="question" ng-show="triggers.isQuestionnaireItemVisible(item, responses)">
                <div ng-class="getQuestionClass()"><span ng-bind-html="label"></span> &nbsp; <span ng-bind-html="required"></span></div>

                <div ng-if="triggers.isQuestionnaireItemVisible(subitem, responses) && item.form=='SINGLECHOICE' && (item.style == 'HORIZONTAL' || item.style == 'VERTICAL')" ng-repeat="subitem in subitems" ng-class="(item.style=='HORIZONTAL') ? 'horizontal' : ''">
                    <input class="radio-custom" name="{{item.id}}" id="{{item.id}}_{{subitem.id}}" value="{{subitem.id}}" ng-model="responses[item.id]"  type="radio">
                    <label for="{{item.id}}_{{subitem.id}}" class="radio-custom-label answer-text"><span ng-bind-html="subitemslabels[subitem.id]"></span></label>
                    <input type="text" class="form-control" id="{{subitem.id}}_A" style="max-width: 500px;" ng-if="subitem.isAdditional && responses[item.id] == subitem.id" ng-model="subitemsTypeText[subitem.id + '_A']" ng-blur="responses[subitem.id + '_A'] = subitemsTypeText[subitem.id + '_A'];"/>
                </div>


                <div ng-if="triggers.isQuestionnaireItemVisible(subitem, responses) && item.form=='MULTIPLECHOICE' &&  item.style == 'VERTICAL'" ng-repeat="subitem in subitems">
                    <input class="checkbox-custom" name="{{item.id}}" id="{{subitem.id}}" value="{{subitem.id}}" ng-model="responses[item.id][subitem.id]" type="checkbox">
                    <label for="{{subitem.id}}" class="checkbox-custom-label answer-text"><span ng-bind-html="subitemslabels[subitem.id]"></span></label>
                    <input type="text" class="form-control" id="{{subitem.id}}_A" style="max-width: 500px;" ng-if="subitem.isAdditional && responses[item.id][subitem.id]" ng-model="subitemsTypeText[subitem.id + '_A']" ng-blur="responses[subitem.id + '_A'] = subitemsTypeText[subitem.id + '_A'];"/>
                </div>


                <div ng-if="triggers.isQuestionnaireItemVisible(subitem, responses) && item.form=='MULTIPLECHOICE' && item.style == 'HORIZONTAL'" ng-repeat="subitem in subitems" class="horizontal-checkbox">
                    <label for="{{subitem.id}}" class="checkbox-custom-label answer-text"><span ng-bind-html="subitemslabels[subitem.id]"></span></label>
                    <input class="checkbox-custom" name="{{item.id}}" id="{{subitem.id}}" value="{{subitem.id}}" ng-model="responses[item.id][subitem.id]" type="checkbox">
                    <input type="text" class="form-control" id="{{subitem.id}}_A" style="max-width: 500px;" ng-if="subitem.isAdditional && responses[item.id][subitem.id]" ng-model="subitemsTypeText[subitem.id + '_A']" ng-blur="responses[subitem.id + '_A'] = subitemsTypeText[subitem.id + '_A'];"/>
                </div>


                <div ng-if="item.form=='SINGLECHOICE' && item.style == 'SELECT'">
                    <select class="form-control" name="{{item.id}}" style="max-width: 500px;" ng-model="responses[item.id]">
		                <option ng-repeat="subitem in subitems" value="{{subitem.id}}" ng-show="triggers.isQuestionnaireItemVisible(subitem, responses)" ng-bind-html="subitemslabels[subitem.id]"></option>
            		</select>
                </div>


                <div ng-if="item.form=='TEXT'" ng-repeat="subitem in subitems">
                    <label for="{{item.id}}_{{subitem.id}}"><span ng-bind-html="subitemslabels[subitem.id]"></span></label>
                    <input type="text" class="form-control" id="{{item.id}}_{{subitem.id}}" ng-if="item.style=='TEXTBIG'" ng-model="subitemsTypeText[subitem.id]" ng-blur="subitemsTypeText[subitem.id] ? responses[item.id][subitem.id]=subitemsTypeText[subitem.id] : responses[item.id][subitem.id]='';" />
                    <input type="text" class="form-control" id="{{item.id}}_{{subitem.id}}" style="max-width: 500px;" ng-if="item.style=='TEXTSMALL'"  ng-model="subitemsTypeText[subitem.id]" ng-blur="subitemsTypeText[subitem.id] ? responses[item.id][subitem.id]=subitemsTypeText[subitem.id] : responses[item.id][subitem.id]='';"/>
                    <textarea name="{{item.id}}_{{subitem.id}}" id="{{item.id}}_{{subitem.id}}" rows="{{item.rows}}" cols="{{item.columns}}" class="form-control" style="max-width: 500px;" ng-if="item.style=='TEXTAREA'" ng-model="subitemsTypeText[subitem.id]" ng-blur="subitemsTypeText[subitem.id] ? responses[item.id][subitem.id]=subitemsTypeText[subitem.id] : responses[item.id][subitem.id]='';"></textarea>
                </div>

                <div class="float_center" ng-if="item.form=='SINGLECHOICE' && item.style == 'RATING'">
					<fieldset class="rating">
                        <div ng-if="0" ng-repeat-start="subitem in subitems | reverse"></div>
                        <input name="{{item.id}}" id="{{item.id}}_{{subitem.id}}" type="radio" value="{{subitem.id}}" ng-model="responses[item.id]" /><label for="{{item.id}}_{{subitem.id}}"><span ng-bind-html="subitemslabels[subitem.id]"></span></label>
                        <div ng-if="0" ng-repeat-end></div>
					</fieldset>
				</div>
                <div class="clear" ng-if="item.form=='SINGLECHOICE' && item.style == 'RATING'"><!-- --></div>
                <div class="nps-legend" ng-if="item.form=='SINGLECHOICE' && item.style == 'RATING'">
                    <span class="legend left" ng-bind-html="corelabels['Q_RATING_LOW']"></span>
                    <span class="legend right" ng-bind-html="corelabels['Q_RATING_HIGH']"></span>
                </div>


                <div ng-if="item.form=='SINGLECHOICE' && item.style == 'SLIDER'">
                    <input input kendo-slider id="{{item.id}}" style="left: 300px; bottom: 0; width: 500px;" ng-model="responses[item.id]"></input>
                </div>
                <div class="clear" ng-if="item.form=='SINGLECHOICE' && item.style == 'SLIDER'"><!-- --></div>
                <div class="nps-legend" ng-if="item.form=='SINGLECHOICE' && item.style == 'SLIDER'">
                    <span class="legend left" ng-bind-html="subitemslabels[subitems[0].id]"></span>
                    <span class="legend right" ng-bind-html="subitemslabels[subitems[subitems.length-1].id]"></span>
                </div>


                <div ng-if="item.form=='MULTIPLECHOICE' && item.style == 'DIVISION'">
                    <table class="division-question">
                    <tr ng-repeat="subitem in subitems" ng-show="triggers.isQuestionnaireItemVisible(subitem, responses)">
                        <td><label for="division-input-2" class="form-control-label"><span ng-bind-html="subitemslabels[subitem.id]"></span></label></td>
                        <td><input type="number" class="form-control division-input" name="{{item.id}}" id="{{item.id}}_{{subitem.id}}" ng-model="responses[item.id][subitem.id]"/></td>
                    </tr>
                    <tr>
                        <td><label for="amount-left" class="form-control-label">{{labels.getQuestionnaireItemLabelByName('Q_DIVISION_REMAINING', settings.respondentLanguage)}}</label></td>
                        <td><div id="amount-left">{{getAswersRemaining()}}</div></td>
                    </tr>
                    <tr>
                        <td><label for="total" class="form-control-label">{{labels.getQuestionnaireItemLabelByName('Q_DIVISION_TOTAL', settings.respondentLanguage)}}</label></td>
                        <td><div id="total">{{getAswersTotal()}}</div></td>
                    </tr>
                    </table>
                </div>


                <div ng-if="item.form=='MULTIPLECHOICE' && item.style == 'DRAGANDDROP'">
                    <ul id="sortablelist_source_{{item.id}}" class="sortable-source ui-sortable" kendo-sortable k-connect-with="'#sortablelist_target_{{item.id}}'" k-on-end="OnChangeSourceList(kendoEvent)" >
                        <li class="ui-state-default" ng-repeat="subitem in sourceDisplayItems" ><span ng-bind-html="subitemslabels[subitem.id]"></span></li>
                    </ul>
                    <ul id="sortablelist_target_{{item.id}}" class="sortable-target ui-sortable" style="min-height: {{getNrAnswers()}}px!important;" kendo-sortable k-connect-with="'#sortablelist_source_{{item.id}}'" k-on-end="OnChangeTargetList(kendoEvent)" >
                        <li class="ui-state-default" ng-repeat="subitem in targetDisplayItems" ><span ng-bind-html="subitemslabels[subitem.id]"></span></li>
                    </ul>
                </div>

                <div class="clear"><!-- --></div>
            </div>
            """
    controller: ($scope, $element, $attrs, $timeout, $sce) ->
        $scope.required           = ''
        $scope.subitemsTypeText   = {}
        $scope.subitems           = $scope.item.items

        if $scope.item.hasOwnProperty('shuffle')
            if $scope.item['shuffle']
                $scope.subitems = shuffle($scope.item.items)


        $scope.initializeLabels = ->
            $scope.label              = $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelById($scope.item.id, $scope.settings.respondentLanguage))
            $scope.subitemslabels     = {}
            $scope.corelabels         = {}

            for subitem in $scope.item.items
                label = $scope.labels.getQuestionnaireItemLabelById(subitem.id, $scope.settings.respondentLanguage)
                $scope.subitemslabels[subitem.id] = $sce.trustAsHtml(label)

            for item in ['Q_RATING_LOW','Q_RATING_HIGH']
                label = $scope.labels.getQuestionnaireItemLabelByName(item, $scope.settings.respondentLanguage)
                $scope.corelabels[item] = $sce.trustAsHtml(label)


            for subitem in $scope.item.items
                if subitem.isAdditional or $scope.item.form == 'TEXT'
                    if subitem.isAdditional
                        subitemid = subitem.id + '_A'
                        if $scope.responses.hasOwnProperty(subitemid)
                            $scope.subitemsTypeText[subitemid] = $scope.responses[subitemid]
                        else
                            $scope.subitemsTypeText[subitemid] = ''
                    if $scope.item.form == 'TEXT'
                        $scope.subitemsTypeText[subitem.id] = ''
                        if $scope.responses.hasOwnProperty($scope.item.id)
                            if $scope.responses[$scope.item.id]
                                if $scope.responses[$scope.item.id].hasOwnProperty(subitem.id)
                                    $scope.subitemsTypeText[subitem.id] = $scope.responses[$scope.item.id][subitem.id]


        if not $scope.responses.hasOwnProperty($scope.item.id)
            if $scope.item.form == 'MULTIPLECHOICE' or $scope.item.form == 'CONTENT'
                $scope.responses[$scope.item.id] = {}
            else
                $scope.responses[$scope.item.id] = null

        if $scope.item.form == 'MULTIPLECHOICE' and  $scope.item.style == 'DIVISION'
            for subitem in $scope.item.items
                if not $scope.responses[$scope.item.id].hasOwnProperty(subitem.id)
                    $scope.responses[$scope.item.id][subitem.id] = 0

        if $scope.item.form == 'MULTIPLECHOICE' and  $scope.item.style == 'DRAGANDDROP'
            $scope.sourceItems        = []
            $scope.targetItems        = []
            $scope.sourceDisplayItems = []
            $scope.targetDisplayItems = []
            $scope.moveItem           = null

            for subitem in $scope.item.items
                if not $scope.responses[$scope.item.id].hasOwnProperty(subitem.id)
                    $scope.responses[$scope.item.id][subitem.id] = -1

            for subitem in $scope.item.items
                if $scope.responses[$scope.item.id].hasOwnProperty(subitem.id)
                    if $scope.responses[$scope.item.id][subitem.id] > -1
                        $scope.targetDisplayItems.push {}

            for subitem in $scope.item.items
                if $scope.responses[$scope.item.id].hasOwnProperty(subitem.id)
                    position = $scope.responses[$scope.item.id][subitem.id]
                    if $scope.responses[$scope.item.id][subitem.id] > -1
                        $scope.targetDisplayItems[position] = {id:subitem.id, text:subitem.text}
                        $scope.targetItems.push subitem.id
                    else
                        if $scope.triggers.isQuestionnaireItemVisible(subitem, $scope.responses)
                            $scope.sourceDisplayItems.push {id:subitem.id, text:subitem.text}
                            $scope.sourceItems.push subitem.id
                else
                    if $scope.triggers.isQuestionnaireItemVisible(subitem, $scope.responses)
                        $scope.sourceDisplayItems.push {id:subitem.id, text:subitem.text}
                        $scope.sourceItems.push subitem.id


        $scope.OnChangeSourceList = (e) ->
            if e.action == 'remove'
                $scope.moveItem = $scope.sourceItems.splice(e.oldIndex, 1)[0]
            if e.action == 'receive'
                $scope.sourceItems.splice(e.newIndex, 0, $scope.moveItem)
            if e.action == 'sort'
                $scope.sourceItems[e.oldIndex] = $scope.sourceItems.splice(e.newIndex, 1, $scope.sourceItems[e.oldIndex])[0]


        $scope.OnChangeTargetList = (e) ->
            if e.action == 'remove'
                $scope.moveItem = $scope.targetItems.splice(e.oldIndex, 1)[0]
            if e.action == 'receive'
                $scope.targetItems.splice(e.newIndex, 0, $scope.moveItem)
            if e.action == 'sort'
                $scope.targetItems[e.oldIndex] = $scope.targetItems.splice(e.newIndex, 1, $scope.targetItems[e.oldIndex])[0]
            for key,value of $scope.responses[$scope.item.id]
                $scope.responses[$scope.item.id][key] = -1
            for subitem in $scope.targetItems
                $scope.responses[$scope.item.id][subitem] = $scope.targetItems.indexOf(subitem)


        $scope.getQuestionClass = ->
            if $scope.item.required
                if $scope.settings.browsingState == 'STATE_CHECK_REQUIRED' and $scope.item.id in $scope.mandatory
                    $scope.required = $sce.trustAsHtml($scope.labels.getQuestionnaireItemLabelByName('REQUIRED', $scope.settings.respondentLanguage))
                    if $scope.item.form == 'MULTIPLECHOICE'
                        if $scope.item.hasOwnProperty('minimum') or $scope.item.hasOwnProperty('maximum')
                            $scope.required = $scope.labels.getQuestionnaireItemLabelByName('REQUIREDMINMAX', $scope.settings.respondentLanguage)
                            if $scope.item.hasOwnProperty('minimum')
                                $scope.required = $scope.required.replace('##MIN##',$scope.item.minimum)
                            if $scope.item.hasOwnProperty('maximum')
                                $scope.required = $scope.required.replace('##MAX##',$scope.item.maximum)
                            $scope.required = $sce.trustAsHtml($scope.required)
                    return 'question-text mandatory-active'
                else
                    $scope.required = ''
                    return 'question-text mandatory'
            else
                $scope.required = ''
                return 'question-text'


        $scope.getAswersRemaining = ->
            total = 0
            max   = 0

            if $scope.item.hasOwnProperty('divisionvalue')
                max = $scope.item['divisionvalue']

            for subitem in $scope.item.items
                if $scope.responses[$scope.item.id].hasOwnProperty(subitem.id)
                    if $scope.responses[$scope.item.id][subitem.id] > -1
                        total = total + $scope.responses[$scope.item.id][subitem.id]
            return max - total

        $scope.getAswersTotal = ->
            if $scope.item.hasOwnProperty('divisionvalue')
                return $scope.item['divisionvalue']
            else
                return 0

        $scope.getNrAnswers = ->
            if $scope.item.hasOwnProperty('items') and window.innerWidth < 480
                return $scope.item['items'].length * 60
            else
                return 100

        $scope.$watch "settings.respondentLanguage", ((newVal, oldVal) ->
            $scope.initializeLabels()
        ), true

        return
]


